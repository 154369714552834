<template>
  <div class="!tw-border-b !tw-border-gray-300 dark:!tw-border-[#1D1D1DF0] !tw-shadow-md">
    <div class="!tw-p-2 tw-flex !tw-items-center !tw-gap-x-3">
      <!-- AVATAR -->
      <div class="tw-relative !tw-w-12 !tw-h-12">
        <img class="!tw-w-12 !tw-h-full !tw-rounded-full"
             :src="avatar"
             :alt="title"
        >

        <!-- NOTIFICATION BUBBLE -->
        <span class="tw-flex tw-justify-center !tw-items-center !tw-h-4 !tw-w-4 tw-absolute !tw-bottom-0 ml-2 !tw-right-[-2px]">
          <span
            :class="[getStatusColor.bg]"
            class="tw-animate-bg-pulsate tw-absolute !tw-inline-flex !tw-h-full !tw-w-full !tw-rounded-full"/>
          <span
            :class="[getStatusColor.bg]"
            class="tw-relative !tw-inline-flex !tw-rounded-full !tw-h-2 !tw-w-2 !tw-text-white !tw-text-xs tw-flex tw-justify-center !tw-items-center"/>
        </span>
      </div>

      <!-- TITLE & SUBTITLE -->
      <div class="!tw-overflow-hidden">
        <span class="!tw-block !tw-font-semibold !tw-text-gray-600 dark:!tw-text-white !tw-truncate">
          {{ title }}
        </span>
        <span class="!tw-block !tw-text-xs !tw-font-light !tw-text-gray-500 dark:!tw-text-white !tw-truncate">
          {{ subtitle }}
        </span>
      </div>

      <button
        v-if="showActionMenuToggle"
        class="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 !tw-shrink-0 tw-ml-auto"
        @click="toggleActionMenu"
      >
        <svg class="!tw-text-[#6A7272]"
             xmlns="http://www.w3.org/2000/svg"
             width="16"
             height="16"
             viewBox="0 0 16 16"
        >
          <g transform="translate(0 16) rotate(-90)">
            <g transform="translate(3.5 6.833)">
              <circle cx="1.5" cy="1.5" r="1.5" transform="translate(8 -0.333)" fill="currentColor"/>
              <circle cx="1.5" cy="1.5" r="1.5" transform="translate(3 -0.333)" fill="currentColor"/>
              <circle cx="1.5" cy="1.5" r="1.5" transform="translate(-2 -0.333)" fill="currentColor"/>
            </g>
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatHeader',

  props: {
    avatar: {
      type: String,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      required: true
    },

    status: {
      type: Boolean,
      default: true
    },

    showActionMenuToggle: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getStatusColor() {
      return {
        bg: this.status ? '!tw-bg-green-500' : '!tw-bg-orange-400',
        bgOpacity: this.status ? '!tw-bg-green-500/50' : '!tw-bg-orange-400/50',
        text: this.status ? '!tw-text-green-600' : '!tw-text-orange-600'
      }
    }
  },

  methods: {
    toggleActionMenu() {
      this.emitter.emit('toggleActionMenu')
    }
  }
}
</script>
