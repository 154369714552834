<template>
  <div
    v-if="settings.showWidget === '1'"
    id="sam-chat-container"
    v-click-outside="onClickOutside"
    class="!tw-fixed !tw-z-[10000] !tw-right-0 !tw-bottom-0 !tw-p-4 !tw-pointer-none"
    :class="[settings.showLeft === '1' ? '!tw-left-0' : '!tw-right-0', { 'tw-dark': settings.darkMode === '1' }]"
  >
    <transition :name="settings.showLeft === '1' ? 'slide-left' : 'slide-right'">
      <chat-toggle-button
        v-show="!showWindow"
        :show-window="showWindow"
        :settings="settings"
        @show-window="toggleChatWindow"
        @show-cta-message="onShowCtaMessage"
      />
    </transition>

    <transition :name="settings.showLeft === '1' ? 'slide-left' : 'slide-right'">
      <div
        v-show="showWindow"
        class="tw-absolute !tw-bottom-4 !tw-rounded-xl blurred-container !tw-backdrop-blur-lg !tw-bg-white/30 dark:!tw-bg-black/70 !tw-w-[320px] !tw-overflow-hidden !tw-shadow"
        :class="[settings.showLeft === '1' ? '!tw-left-4' : '!tw-right-4']"
      >
        <!-- HEADER -->
        <chat-header
          class="!tw-bg-gray-100 dark:!tw-bg-[#1D1D1D]"
          :avatar="settings.avatar"
          :subtitle="settings.subtitle"
          :title="settings.title"
          :show-action-menu-toggle="settings.phone || settings.route"
        />

        <div class="tw-relative">
          <!-- CHAT WINDOW -->
          <chat-window
            ref="chatWindow"
            class="!tw-h-64 !tw-p-2 !tw-pb-6"
            :welcome-message="settings.welcomeMessage"
            :email-message="settings.emailMessage"
            :email-sent-message="settings.emailSentMessage"
          />

          <a v-if="settings.hideSam === '0'"
             href="https://www.samvandewal.com/"
             target="_blank"
             class="tw-absolute !tw-right-0 !tw-bottom-0 !tw-p-1 !tw-text-xs !tw-text-[#A5A5A5]">
            &#x1F680; &nbsp; by SAM
          </a>
        </div>

        <!-- CHAT FOOTER -->
        <chat-footer
          ref="chatFooter"
          :input-placeholder="settings.inputPlaceholder"
          :email="settings.email"
          :phone="settings.phone"
          :show-email-desktop="settings.emailDesktop === '1'"
          :show-email-mobile="settings.emailMobile === '1'"
          :show-action-menu-toggle="settings.phone || settings.route"
          class="!tw-bg-white dark:!tw-bg-[#1D1D1D]"
          @selected-whatsapp="onWhatsappSelection"
          @selected-email="onEmailSelection"
          @send-email="onSendEmail"
        />

        <transition :name="showActionMenu ? 'slide-up' : 'slide-down'">
          <chat-action-menu
            v-if="showActionMenu && (settings.phone || settings.route)"
            :phone="settings.phone"
            :route="settings.route"
            class="tw-absolute tw-inset-x-0 tw-bottom-0"
          />
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import ChatToggleButton from '@/frontend/components/ChatToggleButton'
import ChatHeader from '@/frontend/components/ChatHeader'
import ChatFooter from '@/frontend/components/ChatFooter'
import ChatWindow from '@/frontend/components/ChatWindow'
import axios from '@/shared/axios'
import ChatActionMenu from '@/frontend/components/ChatActionMenu'

export default {
  name: 'ChatWidget',

  components: {
    ChatActionMenu,
    ChatHeader,
    ChatWindow,
    ChatFooter,
    ChatToggleButton
  },

  props: {
    value: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      settings: {
        showWidget: false,
        showLeft: false,
        darkMode: false,

        whatsappTextColor: '',
        whatsappBgColor: '',
        ctaText: '',
        showCtaUrl: '',
        ctaUrlText: '',
        ctaUrlShowIcon: false,
        ctaUrl: '',
        ctaUrlMessage: '',
        ctaUrlTextColor: '',
        ctaUrlBgColor: '',
        title: '',
        subtitle: '',
        welcomeMessage: '',

        inputPlaceholder: '',
        phone: '',
        email: '',
        emailName: '',
        emailMessage: '',
        emailSentMessage: '',
        emailMobile: false,
        emailDesktop: false,
        route: '',

        avatar: '',
        recaptchaKey: '',
        apiUrl: '',
        hideSam: '0',
      },
      showWindow: false,
      showActionMenu: false
    }
  },

  mounted() {
    this.settings = {...this.settings, ...window.wp_sam_chat_config}
    this.emitter.on('toggleActionMenu', () => {
      this.showActionMenu = !this.showActionMenu
    })
  },

  methods: {
    /**
     * @param message
     */
    onWhatsappSelection(message = '') {
      const processedMessage = this.getWhatsappMessage(message)
      this.toggleChatWindow(false)
      window.open(`https://api.whatsapp.com/send?phone=${this.settings.phone}&text=${processedMessage}`, '_blank')
      this.countClick()
      this.$refs.chatFooter.reset()
    },

    /**
     *
     */
    onEmailSelection() {
      this.$refs.chatWindow.addChatMessage(this.settings.emailMessage)
    },

    /**
     *
     */
    onShowCtaMessage({message, button}) {
      const buttonHtml = button.replace('!tw-hidden', '')
      const html = `${message} <a href="${this.settings.ctaUrl}" class="tw-mt-1 tw--mx-4 tw-flex">${buttonHtml}</a>`
      this.$refs.chatWindow.addChatMessage(html)
    },

    /**
     * @param email
     * @param message
     */
    async onSendEmail(email, message) {
      const processedMessage = this.getEmailMessage(message)
      const data = {
        action: 'wp_sam_chat_send_mail',
        email,
        message: processedMessage
      }

      if (this.settings.recaptchaKey) {
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        data.token = await this.$recaptcha('submit')
      }

      axios.post(
        this.settings.apiUrl,
        this.getFormData(data)
      ).then((response) => {
        this.$refs.chatFooter.reset()
        this.$refs.chatWindow.addChatMessage(this.settings.emailSentMessage)
        this.$refs.chatFooter.reset()
        this.countClick()
      }).catch(console.error)
    },

    /**
     * Message function for usage with Whatsapp.
     *
     * @param message
     * @returns {string}
     */
    getWhatsappMessage(message) {
      // %0a%0a is a newline in Whatsapp
      return this.getMessage(message, '*##*', '%0a%0a')
    },

    /**
     * Message function for usage with Email.
     *
     * @param message
     * @returns {string}
     */
    getEmailMessage(message) {
      return this.getMessage(message, '<strong>##</strong>', '<br/><br/>')
    },

    /**
     * @param message
     * @param titleTemplate
     * @param newLine
     * @returns {string}
     */
    getMessage(message, titleTemplate = '##', newLine) {
      let slug = window.location.pathname
      if (slug !== '/') {
        slug = slug.split('/').filter(e => e).slice(-1)[0]
        slug = slug.replace('-', ' ')
      }

      const newTitleValue = slug === '/' ? 'Verstuurd via chatvenster:' : `Pagina: ${slug}`
      const title = titleTemplate.replace('##', newTitleValue)
      return `${title} ${newLine} ${message}`
    },

    onClickOutside() {
      setTimeout(() => {
        if (this.showWindow) {
          this.toggleChatWindow(false)
        }
      }, 10)
    },

    /**
     * @param {Boolean} show
     */
    toggleChatWindow(show) {
      this.showActionMenu = false
      this.showWindow = show
      if (show) {
        this.$refs.chatWindow.scrollToBottom()
      }
    },

    countClick() {
      const data = {
        action: 'wp_sam_chat_button_clicked',
      }

      axios.post(
        this.settings.apiUrl,
        this.getFormData(data)
      ).catch(console.error)
    },

    /**
     * @param object
     * @returns {FormData}
     */
    getFormData(object) {
      const formData = new FormData()
      Object.keys(object).forEach(key => formData.append(key, object[key]))
      return formData
    }
  }
}
</script>

<style scoped>
/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .blurred-container {
    background-color: rgba(245, 245, 245, .95) !important;
  }

  .tw-dark .blurred-container {
    background-color: rgba(10, 10, 10, .95) !important;
  }
}

/* Slide left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.5s ease !important;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(calc(-100% - 16px)) !important;
}


/* Slide right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.5s ease !important;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(calc(100% + 16px)) !important;
}

/* Slide up */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease !important;
  transform: translateY(0) !important;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(calc(100% + 16px)) !important;
}

/* Slide down */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.3s ease !important;
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(calc(100% - 16px)) !important;
}

</style>
