import axios from './axios'

/**
 * @param {any} win
 */
export default function (win: any) {
  win.$appConfig = win.wp_sam_plugin_config || {}
  win.$appConfig.axios = axios

  return win
}
