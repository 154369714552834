<template>
  <div>
    <ul
      id="actions"
      ref="actions"
      class="!tw-rounded-xl !tw-bg-white dark:!tw-bg-[#2C2C2E] !tw-shadow-xl"
    >
      <li v-if="phone" class="!tw-px-3 !tw-flex !tw-items-center !tw-text-blue-500 hover:!tw-cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M3,4.5V6.507a14.5,14.5,0,0,0,14.5,14.5h2a1.5,1.5,0,0,0,1.5-1.5V16.942a1.5,1.5,0,0,0-1.026-1.423l-3.183-1.061a1.5,1.5,0,0,0-1.722.591l-.729,1.093a1.5,1.5,0,0,1-1.722.591l-1.525-.508a4.932,4.932,0,0,1-3.2-3.381l-.627-2.3a1.5,1.5,0,0,1,.973-1.818l.6-.2a1.35,1.35,0,0,0,.9-1.5L9.285,4.253A1.5,1.5,0,0,0,7.806,3H4.5A1.5,1.5,0,0,0,3,4.5Zm-1,0A2.5,2.5,0,0,1,4.5,2H7.806a2.5,2.5,0,0,1,2.466,2.089l.462,2.769A2.35,2.35,0,0,1,9.158,9.474l-.6.2a.5.5,0,0,0-.324.606l.627,2.3a3.932,3.932,0,0,0,2.55,2.7l1.525.508a.5.5,0,0,0,.574-.2l.729-1.093a2.5,2.5,0,0,1,2.871-.985l3.183,1.061A2.5,2.5,0,0,1,22,16.942v2.565a2.5,2.5,0,0,1-2.5,2.5h-2A15.5,15.5,0,0,1,2,6.507Z"
                transform="translate(0 -0.003)"
                fill="currentColor"/>
        </svg>

        <span
          class="!tw-block !tw-w-full !tw-text-base !tw-ml-2 !tw-py-3"
          @click="onPhone">
          Bel direct
        </span>
      </li>

      <li v-if="route" class="!tw-px-3 !tw-flex tw-items-center !tw-text-blue-500 !tw-border-t-[1px] !tw-border-[#CAD2D8] dark:!tw-border-[#545458A6] hover:!tw-cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M12,14a4,4,0,1,1,4-4A4,4,0,0,1,12,14Zm0-1a3,3,0,1,0-3-3A3,3,0,0,0,12,13Zm.339,7.867a.5.5,0,0,1-.678,0C7.235,16.782,5,13.172,5,10a7,7,0,0,1,14,0C19,13.172,16.765,16.782,12.339,20.867ZM18,10A6,6,0,0,0,6,10c0,2.752,1.987,6.035,6,9.816C16.013,16.035,18,12.752,18,10Z"
                fill="currentColor"/>
        </svg>

        <span class="!tw-block !tw-w-full !tw-text-base !tw-ml-2 !tw-py-3"
        @click="onRoute">
          Route
        </span>
      </li>
    </ul>

    <button
      class="tw-rounded-t-xl !tw-mt-2 !tw-py-4 !tw-bg-white dark:!tw-bg-[#2C2C2E] !tw-flex !tw-w-full tw-justify-center tw-items-center !tw-text-base !tw-text-blue-500"
      @click="toggleActionMenu">
      Annuleren
    </button>
  </div>
</template>

<script>
export default {
  name: 'ChatActionMenu',

  props: {
    phone: {
      type: String,
      default: ''
    },

    route: {
      type: String,
      default: ''
    }
  },

  methods: {
    toggleActionMenu() {
      this.emitter.emit('toggleActionMenu')
    },

    onPhone() {
      this.toggleActionMenu()
      location.href = 'tel:+' + this.phone
    },

    onRoute() {
      this.toggleActionMenu()
      window.open(this.route, '_blank')
    }
  }
}
</script>
