import { createApp } from 'vue'
// @ts-ignore
import vClickOutside from 'click-outside-vue3'
import App from './App.vue'
import '@/shared/index'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import mitt from 'mitt'
const emitter = mitt()

const app = createApp(App)
// @ts-ignore
const siteKey = window.wp_sam_chat_config?.recaptchaKey || ''
if (siteKey) {
  app.use(VueReCaptcha, { siteKey })
}

app.config.globalProperties.emitter = emitter
app.use(vClickOutside)
app.mount('#sam-chat-frontend')
