<template>
  <ul
    id="messages"
    ref="messages"
    class="!tw-overflow-scroll tw-flex-column !tw-space-y-2"
  >
    <chat-message
      v-for="(message, index) of messages"
      :key="index"
    >
      <span v-html="message"></span>
    </chat-message>
  </ul>
</template>

<script>
import ChatMessage from '@/frontend/components/ChatMessage'

export default {
  name: 'ChatWindow',

  components: {
    ChatMessage
  },

  props: {
    welcomeMessage: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      messages: []
    }
  },

  created() {
    this.addChatMessage(this.welcomeMessage)
  },

  methods: {
    addChatMessage(message) {
      const replaceValues = message
        .replace('$groet', this.getGreeting())

      // if (this.messages.includes(replaceValues)) return
      this.messages.push(replaceValues)
      this.scrollToBottom()
    },

    scrollToBottom() {
      setTimeout(() => {
        const messagesElement = this.$refs.messages
        const top = messagesElement.scrollHeight - messagesElement.clientHeight
        messagesElement.scrollTo({ top, behavior: 'smooth' })
      }, 0)
    },

    /**
     *
     */
    getGreeting() {
      const hours = new Date().getHours()
      const isNight = (hours < 6)
      const isMorning = (hours >= 6 && hours < 12)
      const isAfternoon = (hours >= 12 && hours < 18)
      const isEvening = (hours >= 18 && hours < 24)

      if (isNight) {
        return 'Goedennacht'
      }

      if (isMorning) {
        return 'Goedemorgen'
      }

      if (isAfternoon) {
        return 'Goedemiddag'
      }

      if (isEvening) {
        return 'Goedenavond'
      }

      return 'Goedendag'
    },
  }
}
</script>

<style scoped>
  /* Hide scrollbar for Chrome, Safari and Opera */
  ul::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  ul {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
</style>
