<template>
  <div>
    <div v-if="!showEmailInput" class="tw-flex tw-flex-col !tw-space-y-2 !tw-p-2">
      <div class="tw-flex !tw-items-center !tw-gap-x-2">
        <button
          v-if="showActionMenuToggle && !hasTextValue"
          class="!tw-flex !tw-items-center !tw-justify-center !tw-w-5 !tw-h-5 !tw-shrink-0 !tw-text-blue-500"
          @click="toggleActionMenu"
        >
          <svg height="256"
               viewBox="0 0 256 256"
               width="256"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="m86.5 41.5h-38v-38c0-1.933-1.567-3.5-3.5-3.5s-3.5 1.567-3.5 3.5v38h-38c-1.933 0-3.5 1.567-3.5 3.5s1.567 3.5 3.5 3.5h38v38c0 1.933 1.567 3.5 3.5 3.5s3.5-1.567 3.5-3.5v-38h38c1.933 0 3.5-1.567 3.5-3.5s-1.567-3.5-3.5-3.5z"
              fill="currentColor"
              transform="matrix(2.8008 0 0 2.8008 1.964 1.964)"/>
          </svg>
        </button>

        <!-- INPUT -->
        <textarea
          ref="textarea"
          v-model="value"
          class="!tw-text-base !tw-leading-4 !tw-w-full !tw-min-h-[40px] !tw-p-3 !tw-rounded-3xl !tw-bg-gray-200 dark:!tw-bg-[#7676801F] !tw-text-gray-600 dark:!tw-text-[#EBEBF599] placeholder:!tw-text-gray-400 placeholder:!tw-text-base placeholder:!tw-leading-4 !tw-border-0 focus:!tw-outline-none"
          rows="1"
          :placeholder="inputPlaceholder"
          @input="autoResizeTextarea"
        />

        <!-- BUTTON -->
        <button
          v-show="!hasTextValue || !showEmailOption"
          :disabled="!hasTextValue"
          class="tw-flex !tw-items-center tw-justify-center !tw-rounded-full !tw-transition-all !tw-bg-blue-500 hover:!tw-bg-blue-700 disabled:!tw-cursor-not-allowed !tw-w-10 !tw-h-10 !tw-text-white !tw-shrink-0 !tw-border-0"
          @click="sendWhatsapp"
        >
          <svg class="!tw-w-4 !tw-h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z">
            </path>
          </svg>
        </button>
      </div>

      <!-- Button group -->
      <div v-if="hasTextValue && showEmailOption" class="tw-flex !tw-items-center !tw-gap-x-2">
        <!-- BUTTON -->
        <button
          class="!tw-px-3 tw-flex !tw-text-base tw-flex-1 !tw-items-center !tw-rounded-full !tw-transition-all !tw-bg-blue-500 hover:!tw-bg-blue-700 !tw-h-10 !tw-text-white !tw-shrink-0 !tw-border-0"
          @click="onEmailSelection"
        >
          <svg class="!tw-w-4 !tw-h-4 !tw-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 17">
            <g fill="currentColor">
              <path
                d="m162.07 291.135 2.2.526c.174.029.2.117.116.263l-1.823 3.245c-.087.146-.26.117-.26-.059l-.231-3.976z"
                transform="translate(-154.978 -278.263)"
              />
              <path
                d="m6.571 12.1-6.365-2.042c-.26-.117-.289-.234 0-.38l18.574-9.617c.174-.117.26-.059.2.117l-3.412 14.558c-.029.088-.087.088-.145.059l-7.174-2.195 6.886-8.39z"
              />
            </g>
          </svg>

          Email
        </button>

        <!-- BUTTON -->
        <button
          class="!tw-px-3 tw-flex !tw-text-base tw-flex-1 !tw-items-center !tw-rounded-full !tw-transition-all !tw-bg-blue-500 hover:!tw-bg-blue-700 !tw-h-10 !tw-text-white !tw-shrink-0 !tw-border-0"
          @click="sendWhatsapp"
        >
          <svg class="!tw-w-4 !tw-h-4 !tw-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z">
            </path>
          </svg>

          Whatsapp
        </button>
      </div>
    </div>

    <div
      v-else
      class="tw-flex tw-flex-col !tw-space-y-2 !tw-p-2"
    >
      <!-- INPUT -->
      <input
        v-model="emailValue"
        class="email-input transition-all !tw-text-base !tw-leading-4 !tw-w-full !tw-p-3 !tw-h-10 !tw-rounded-3xl !tw-bg-gray-200 dark:!tw-bg-[#7676801F] !tw-text-gray-600 dark:!tw-text-[#EBEBF599] placeholder:!tw-text-gray-400 placeholder:!tw-text-base placeholder:!tw-leading-4 focus:!tw-outline-none"
        :class="{'is-invalid !tw-border !tw-border-red-700': emailInvalid}"
        type="email"
        autocomplete="email"
        placeholder="E-mailadres"
        @keydown.enter.prevent="sendEmail"
      />

      <!-- BUTTON -->
      <button
        class="!tw-px-3 tw-flex !tw-text-base !tw-items-center !tw-rounded-full !tw-transition-all !tw-bg-blue-500 hover:!tw-bg-blue-700 !tw-h-10 !tw-text-white !tw-shrink-0 !tw-border-0"
        @click="sendEmail"
      >
        <svg class="!tw-w-4 !tw-h-4 !tw-mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z">
          </path>
        </svg>

        Versturen
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatFooter',

  props: {
    inputPlaceholder: {
      type: String,
      required: true
    },

    email: {
      type: String,
      required: true
    },

    phone: {
      type: String,
      required: true
    },

    showEmailMobile: {
      type: Boolean,
      required: true
    },

    showEmailDesktop: {
      type: Boolean,
      required: true
    },

    showActionMenuToggle: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      value: '',
      emailValue: '',
      showEmailInput: false,
      emailInvalid: false
    }
  },

  computed: {
    hasTextValue() {
      return this.value
    },

    /**
     * @returns {boolean}
     */
    showEmailOption() {
      let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

      if (this.showEmailMobile && isMobile) {
        return true
      } else if (this.showEmailDesktop && !isMobile) {
        return true
      }

      return false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.autoResizeTextarea()
    })
  },

  methods: {
    sendWhatsapp() {
      this.$emit('selected-whatsapp', this.value)
    },

    onEmailSelection() {
      this.showEmailInput = true
      this.$emit('selected-email')
    },

    sendEmail() {
      this.emailInvalid = false

      const isValidEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailValue)
      if (!isValidEmail) {
        this.emailInvalid = true
        return false
      }

      this.$emit('send-email', this.emailValue, this.value)
    },

    onTextAreaEnter(event) {
      event.preventDefault()

      if (this.hasTextValue && this.showEmailOption) {
        this.onEmailSelection()
        return
      }

      this.sendWhatsapp()
    },

    reset() {
      this.emailValue = ''
      this.value = ''
      this.showEmailInput = false
      this.emailInvalid = false
      this.autoResizeTextarea()
    },

    /**
     */
    autoResizeTextarea() {
      const target = this.$refs.textarea
      if (!target) {
        return
      }

      target.style.height = 'auto'
      target.style.height = `${target.scrollHeight}px`
    },

    toggleActionMenu() {
      this.emitter.emit('toggleActionMenu')
    }
  }
}
</script>

<style scoped>
.email-input {
  border: none;
}

.email-input:not(.is-invalid) {
  border: none !important;
}

textarea::-webkit-scrollbar {
  display: none;
}

textarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
